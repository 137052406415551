import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "@nuxtjs/composition-api";
import { useIntersectionObserver, templateRef } from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  items: {
    type: Array,
    required: true,
  },
  mediaSizes: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props



const tpl_carouselWrapper = templateRef("tpl_carouselWrapper");
const tpl_carousel = templateRef("tpl_carousel");
const tpl_pager = templateRef("tpl_pager");
const swiper = ref(null);
const pager = ref(null);
const nextSlideCountdown = ref(null);
const targetIsVisible = ref(false);
const videoPlayerTarget = ref(false);
const route = useRoute();
const router = useRouter();
const allowAutoplay = ref(true);

const { stop } = useIntersectionObserver(
  tpl_carouselWrapper,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible.value = isIntersecting;
  }
);

watch(targetIsVisible, (newVal, oldVal) => {
  if (newVal) {
    allowAutoplay.value = true;
    if (swiper.value.realIndex === 0) {
      setTimeout(handleNextSlide, 1000);
    } else {
      //pager.value.slideTo(0, 0, false);
      //swiper.value.slideTo(0, 0, true);
      handleAutoPlay("resume");
    }
  } else {
    allowAutoplay.value = false;
    handleAutoPlay("pause");
  }
});

function handleModalHide() {
  allowAutoplay.value = true;
  videoPlayerTarget.value = false;

  /*patch to avoid swiper weird behaviour on the first time modal vp is open*/
  $(swiper.value.slides[swiper.value.realIndex]).addClass(
    "swiper-slide-active"
  );

  $(pager.value.slides[swiper.value.realIndex]).addClass(
    "swiper-slide-thumb-active"
  );

  handleAutoPlay("resume");
}

function handleClick(index) {
  allowAutoplay.value = false;
  handleAutoPlay("pause");

  if (props.items[index].to) {
    router.push(`/${route.value.params.locale}${props.items[index].to}`);
  } else if (props.items[index].video) {
    videoPlayerTarget.value = {
      src: props.items[index].video.src,
      poster: props.items[index].video.poster,
    };
  }
}

function handleNextSlide() {
  if (swiper.value && pager.value) {
    if (nextSlideCountdown.value) {
      nextSlideCountdown.value.kill();
    }
    if (props.items.length < 2) return;
    nextSlideCountdown.value = gsap.fromTo(
      $(pager.value.slides[swiper.value.realIndex]).find("span:nth-of-type(2)"),
      { scaleX: 0 },
      {
        duration: 10,
        scaleX: 1,
        force3D: "auto",
        ease: "Linear.easeNone",
        onComplete: () => {
          swiper.value.slideNext(800, true);
        },
      }
    );
  }
}

function handleAutoPlay(state) {
  if (nextSlideCountdown.value) {
    switch (state) {
      case "pause":
        nextSlideCountdown.value.pause();
        break;
      case "resume":
        if (allowAutoplay.value) {
          nextSlideCountdown.value.resume(
            nextSlideCountdown.value.timeScale() || 0.001
          );
        }
        break;
    }
  }
}

onMounted(() => {
  function onDuplicateClick(event) {
    event.stopPropagation();
    handleClick(event.currentTarget.getAttribute("data-button-index"));
  }

  pager.value = new Swiper(tpl_pager.value, {
    slidesPerView: props.items.length >= 3 ? 3 : 2,
    spaceBetween: 2,
    speed: 400,
    threshold: 10,
    preventClicks: false,
    resistanceRatio: 0.5,
    roundLengths: true,
    breakpoints: {
      600: {
        speed: 600,
      },
    },
  });

  swiper.value = new Swiper(tpl_carousel.value, {
    loop: props.items.length > 1,
    allowTouchMove: props.items.length > 1,
    parallax: { enabled: true },
    slidesPerView: 1,
    speed: 650,
    grabCursor: props.items.length > 1,
    threshold: 10,
    resistanceRatio: 0.68,
    thumbs: {
      swiper: pager.value,
      multipleActiveThumbs: false,
    },
    roundLengths: true,
    on: {
      slideChange: function (sw) {
        if (nextSlideCountdown.value) {
          nextSlideCountdown.value.kill();
          nextSlideCountdown.value = null;
        }
        handleNextSlide();

        let $removeEl =
          tpl_carouselWrapper.value?.querySelector("[loop-event-click]");

        if ($removeEl) {
          $removeEl.removeEventListener("click", onDuplicateClick);
        }

        setTimeout(() => {
          //
          if (
            sw.slides[sw.activeIndex].classList.contains(
              "swiper-slide-duplicate"
            )
          ) {
            let $el = sw.slides[sw.activeIndex].querySelector(
              "[data-button-index]"
            );

            $el.setAttribute("loop-event-click", true);
            $el.addEventListener("click", onDuplicateClick);

            const $vid = sw.slides[sw.activeIndex].querySelector("video");

            if ($vid) {
              $vid.play();
            }
          } else {
            sw.slides[sw.activeIndex]
              .querySelectorAll("swiper-slide-duplicate")
              .forEach((el) => {
                const $vid = el.querySelector("video");
                if ($vid) {
                  $vid.pause();
                }
              });
          }
          //
        }, 500);
      },
    },
  });

  //bug fix: don't remove
  pager.value.update();

  // mobile fix
  if (props.items.length > 1) {
    setTimeout(() => {
      swiper.value.loopDestroy();
      swiper.value.loopCreate();
      swiper.value.update();
    }, 1000);
  }
});

return { __sfc: true,props, tpl_carouselWrapper, tpl_carousel, tpl_pager, swiper, pager, nextSlideCountdown, targetIsVisible, videoPlayerTarget, route, router, allowAutoplay, stop, handleModalHide, handleClick, handleNextSlide, handleAutoPlay }
}

}